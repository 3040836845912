var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 py-3 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-full rounded-lg shadow pb-20"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('button', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3",
    class: _vm.$colors.primary,
    on: {
      "click": function click($event) {
        return _vm.RouterBack();
      }
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("chevron_left")])]), _c('div', {
    staticClass: "text-xl font-bold text-green-700"
  }, [_vm._v(_vm._s(_vm.$t('order_details')))])])]), _c('div', {
    attrs: {
      "id": "detail"
    }
  }, [_c('div', {
    staticClass: "flex pr-5 pl-5 space-x-3"
  }, [_c('div', {
    staticClass: "flex justify-between shadow w-full h-auto p-4 my-1 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(0), _c('div', {
    staticClass: "ml-5 w-full space-y-2"
  }, [_c('div', {
    staticClass: "text-base mt-2 mb-2 font-semibold text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('detail')) + " ")]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('order_id')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.id) + " ")])]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('store')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.seller && _vm.formData.seller.company || _vm.formData.seller && _vm.formData.seller.fullName) + " ")])]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('shipping_address')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.customer.shippingAddress) + " ")])]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('total_price')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.totalPrice) + " "), _c('sup', {
    staticClass: "uppercase"
  }, [_vm._v(_vm._s(_vm.$f.getcurrencyCodeByCountry(_vm.dataCountry, _vm.formData.customer.country)))])])]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('total_fees')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.totalFees) + " "), _c('sup', {
    staticClass: "uppercase"
  }, [_vm._v(_vm._s(_vm.$f.getcurrencyCodeByCountry(_vm.dataCountry, _vm.formData.customer.country)))])])]), _vm.ststu === 'Cancelled' ? _c('div', {
    staticClass: "text-sm text-bold-600 font-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('cancellation_reason')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.reason) + " ")])]) : _vm._e(), _vm.consfirmDate ? _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('confirmation_date')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.consfirmDate) + " ")])]) : _vm._e(), _vm.shippDate ? _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('shipping_date')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.shippDate) + " ")])]) : _vm._e()])])]), _c('div', {
    staticClass: "flex justify-between shadow w-full h-auto p-4 my-1 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(1), _c('div', {
    staticClass: "ml-5 w-full"
  }, [_c('div', {
    staticClass: "text-base mt-2 mb-2 font-semibold text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('customer_information')) + " ")]), _c('div', {
    staticClass: "text-sm font-light text-gray-700"
  }, [_c('i', {
    staticClass: "material-icons text-xs text-gray-400"
  }, [_vm._v("person")]), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.customer.fullName) + " ")])]), _c('div', {
    staticClass: "text-sm font-light text-gray-700"
  }, [_c('i', {
    staticClass: "material-icons text-xs text-gray-400"
  }, [_vm._v("location_city")]), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$f.getCountryNameByCode(_vm.options, _vm.formData.customer.country)) + " ")])]), _c('div', {
    staticClass: "text-sm font-light text-gray-700"
  }, [_c('i', {
    staticClass: "material-icons text-xs text-gray-400"
  }, [_vm._v("location_city")]), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.customer.city) + " ")])]), _c('div', {
    staticClass: "text-sm font-light text-gray-700"
  }, [_c('i', {
    staticClass: "material-icons text-xs text-gray-400"
  }, [_vm._v("room")]), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.customer.address) + " ")])]), _c('div', {
    staticClass: "text-sm font-light text-gray-700"
  }, [_c('i', {
    staticClass: "material-icons text-xs text-gray-400"
  }, [_vm._v("phone")]), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.customer.phone) + " ")])])])])])]), _c('div', {
    staticClass: "flex pr-5 pl-5 mt-6"
  }, [_c('div', {
    staticClass: "flex justify-between w-full h-auto p-4 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(2), _c('div', {
    staticClass: "ml-1 w-full p-2"
  }, [_c('div', {
    staticClass: "text-base font-semibold text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('products_order')) + " ")]), _c('div', {
    staticClass: "w-full mt-4"
  }, [_c('table', {
    staticClass: "min-w-full leading-normal"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t('product')) + " ")]), _c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t('quantity')) + " ")]), _c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t('price')) + " ")]), _c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t('actions')) + " ")])])]), _vm._l(_vm.formData.details, function (item, index) {
    return _c('tbody', {
      key: item._id
    }, [_c('tr', {
      class: index % 2 == 0 ? 'bg-gray-100' : ''
    }, [_c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-sm"
    }, [_c('span', {
      staticClass: "flex text-gray-700 capitalize whitespace-no-wrap"
    }, [_c('span', {
      staticClass: "mt-1"
    }, [_vm._v(_vm._s(item.product.name))])])]), _c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-xs"
    }, [_c('p', {
      staticClass: "text-gray-700 ml-6 whitespace-no-wrap"
    }, [_c('span', {
      staticClass: "font-semibold"
    }, [_vm._v(_vm._s(item.quantity) + " ")])])]), _c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-xs"
    }, [_c('span', {
      staticClass: "justify-center text-xs text-center pl-2 pr-2 py-1 rounded-full text-gray-700"
    }, [_c('span', {
      staticClass: "text-gray-800 font-semibold text-xs ml-1"
    }, [_vm._v(" " + _vm._s(item.unitPrice) + " ")]), _c('sup', {
      staticClass: "uppercase"
    }, [_vm._v(_vm._s(_vm.$f.getcurrencyCodeByCountry(_vm.dataCountry, _vm.formData.customer.country)))])])]), _c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-xs"
    }, [_c('button', {
      staticClass: "rounded-full flex justify-center items-center focus:outline-none w-8 h-8 mr-3 mt-1",
      class: _vm.$colors.actionTable,
      on: {
        "click": function click($event) {
          return _vm.ShowPopUpInformation(index, item.product);
        }
      }
    }, [_c('i', {
      staticClass: "material-icons mx-1"
    }, [_vm._v("remove_red_eye")])])])]), _c('vue-modal-2', {
      attrs: {
        "name": 'modal_' + index,
        "headerOptions": {
          title: _vm.$t('detail_product')
        },
        "noFooter": true,
        "modalSize": "lg"
      },
      on: {
        "on-close": function onClose($event) {
          return _vm.closePopUpInformation(index);
        }
      }
    }, [_c('div', {
      staticClass: "flex items-center lg:p-10 overflow-hidden relative"
    }, [_c('div', {
      staticClass: "w-full max-w-6xl rounded bg-white shadow-xl lg:p-20 mx-auto text-gray-800 relative md:text-left"
    }, [_c('div', {
      staticClass: "md:flex items-center"
    }, [_c('div', {
      staticClass: "w-full md:w-1/2 px-10 mb-10 md:mb-0"
    }, [_c('div', {
      staticClass: "relative"
    }, [_c('img', {
      staticClass: "w-full relative z-10",
      attrs: {
        "src": _vm.selectProduct.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg',
        "alt": ""
      }
    }), _c('div', {
      staticClass: "border-4 border-yellow-200 absolute top-10 bottom-10 left-10 right-10 z-0"
    })])]), _c('div', {
      staticClass: "w-full md:w-1/2 px-10"
    }, [_c('div', {
      staticClass: "mb-10"
    }, [_c('h1', {
      staticClass: "font-bold uppercase text-2xl mb-5"
    }, [_vm._v(_vm._s(_vm.selectProduct.name))]), _c('p', {
      staticClass: "text-sm"
    }, [_vm._v(" " + _vm._s(_vm.selectProduct.description) + " ")])])])])])]), _c('div', {
      staticClass: "modal-footer overflow-auto px-5 border0-t text-right"
    }, [_c('button', {
      staticClass: "flex text-white mx-auto max-w-sm mb-2 rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right",
      on: {
        "click": function click($event) {
          return _vm.closePopUpInformation(index);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t('close')))])])])], 1);
  })], 2)])])])])]), _vm.$f.getAccessPermission(_vm.currentUser, 'statusHistories', 'read') ? _c('div', {
    staticClass: "flex pr-5 pl-5 mt-6"
  }, [_c('div', {
    staticClass: "flex justify-between w-full h-auto p-4 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(3), _c('div', {
    staticClass: "ml-1 w-full p-2"
  }, [_c('div', {
    staticClass: "text-base font-semibold text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('status_history')) + " ")]), _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "overflow-x-auto mt-4"
  }, [_c('base-table', {
    attrs: {
      "content": _vm.content,
      "headers": _vm.headers,
      "name": "statusHistories",
      "refresh": _vm.refresh,
      "idWharhouse": _vm.idWharhouse,
      "currentUser": _vm.currentUser
    }
  })], 1)])])])])]) : _vm._e()])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-100"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("list_alt")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-100"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("person")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-100"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_mall")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-100"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("history")])]);

}]

export { render, staticRenderFns }